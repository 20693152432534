<template lang="pug">
  v-container.login.pa-0.heigth-100(fluid)
    v-row.align-center
      v-col.d-none.d-md-flex.heigth-100(cols="5")
        v-img.heigth-100(:src="img.background_login")
      v-col.pt-10(cols="12" md="7")
        v-row.flex-column.align-center.mt-10.mt-sm-auto.px-10.text-center
          v-img(:src="img.logo" max-width="213")
          h1.mt-5 #[translate Disposables Configurator]
          p.primary--text.font-weight-light.subtitle #[translate Find the right material with the properties you are looking for!]
          v-img.my-3(:src="img.ue")
          span.opacity-50.mt-5.disposable_gray--text #[translate If you forgot your password, put your email address and we will send instructions to restore your password.]
          .blockError
            p.pt-5(v-if="errorMsg") #[translate Enter a valid email address]
          form#form_restore.mt-5(v-if="!restoreSend")
            div.margin-bottom-32
              v-text-field.field-login(v-model="email" :label="placeholder.email" @focus="resetError ()")
            div.text-center
              v-btn.btn-app.mb-0(color="primary" type="button" width="167" height="45" @click="restore" @keyup.enter="restore" :disabled="!email") #[translate Restore password]
          v-row.mt-15(v-if="restoreSend")
            v-col.text-center(cols="12")
              v-img.mx-auto.mb-5(:src="img.smile" width="20" height="20" )
              p.mb-10 #[translate You will receive an instruction shortly.]
          v-btn.btn-app.mb-0.mt-15(color="primary" type="button" width="167" height="45" @click="goLogin") #[translate Go login]
          p.mt-15.copy #[translate Copyright © 2021 INPACS GmbH]
</template>

<script>
import SrvLogin from '@/services/login.js';

export default {
  name: 'forgot',
  data() {
    return {
      restoreSend: false,
      errorMsg: '',
      img: {
        background_login: require('@/assets/img/login/fondo_login.png'),
        ue: require('@/assets/img/login/EU_directive.png'),
        logo: require('@/assets/img/logo.png'),
        smile: require('@/assets/img/login/smile.png'),
      },
      email: null,
      placeholder: {
        'email': this.$gettext('email'),
      },
      //page_password_reset: this.$config.page_password_reset,
      RememberCheck: false,
      inviteOk: false
    }
  },
  created() {

  },
  components: {
  },
  methods: {
    resetError () {
      this.errorMsg = ''
    },

    restore () {
      SrvLogin.getForgot({email : this.email})
        .then(res => {     
          console.log(res)
          this.restoreSend = true
        }, (error) => {
          this.errorMsg = error.data.email ? error.data.email[0] : true
        })
    },
    goLogin () {
      this.$router.push({name: 'home'})
    }
  }
}
</script>
<style lang="scss" >
  .heigth-100{
    height: 100vh;
  }
  .login{
    h1{
      font-family: 'Roboto Slab';
      font-size: 28px;
      color: #003A70;
      font-weight: bold;

    }
    .subtitle{
      font-size: 20px;
    }
    .blockError{
      height: 10px;
      p{
        color:#EC4444
      }
    }
    #form_restore{
      width:100%;
      max-width: 360px;
      .field-login{
        .v-label{
          font-size: 15px;
          color: var(--v-disposable_gray-base);
        }
        input{
          font-size: 15px;
        }
      }

    }

    .copy{
      font-size: 10px;
    }
  }

</style>