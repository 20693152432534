<template lang="pug">
  .configurator
    //navbar
    appHeader

    // BLOCK PRODUCTS
    v-row.block-products.container.mx-auto.justify-center 
      v-col(cols="12")
        p.mb-0.title-block.primary--text.mt-8.mt-sm-0 #[translate Select product you need]:
      v-col(cols="12")
        v-row.products.justify-center
          v-col.row.product(v-for="(product, index) in products" :key="product.slug" :class="{'ma-n3' : index > 0}")
            v-col.align-self-center(cols="4")
              v-img.ml-auto(:src="img[product.slug]" width="81" height="81" )
            v-col(cols="7")
               v-checkbox.check-products(v-model="checkboxProduct" :value="product.slug" :label="product.label" color="#3A84FF" hide-details off-icon="mdi-checkbox-blank-outline" on-icon="mdi-checkbox-intermediate" )
               p.text-desciption.mb-2 {{product.description}}
            v-col(cols="1")

    // BLOCK MATERIAL PROPERTIES
    v-row.block-properties.container.mx-auto.mt-8.pa-0
      v-col.py-0(cols="12")
        p.mb-0.text-sm-center.title-block #[translate Mark all desired material properties]:
      v-col(cols="12")
        v-row.properties.ma-0.justify-center
          v-col.row.propertie(v-if="property.property_type === 'material'" v-for="(property, index) in properties" :key="property.slug" :class="{'property_selected' : checkboxProperty.includes( property.slug )}")
            .block-img.mt-auto.mx-auto
              v-img(:src="getImgUrl(property.slug)" mx-width="47" max-height="50" contain)
            .block-check.pl-3
              v-checkbox(v-model="checkboxProperty" :value="property.slug" :label="property.label" color="#3A84FF" hide-details off-icon="mdi-checkbox-blank-outline" on-icon="mdi-checkbox-intermediate" :disabled="!checkboxProduct")
            tooltip(v-if="property.description" :description="property.description")

    // BLOCK SUSTAINABILITY PROPERTIES
    v-row.block-sustainability.container.mx-auto.mt-8.pa-0
      v-col.py-0(cols="12")
        p.mb-0.text-sm-center.title-block #[translate Mark all desired sustainability properties]:
      v-col(cols="12")
        v-row.sustainabilities.ma-0.justify-center
          v-col.row.sustainability(v-if="property.property_type === 'sustainability'" v-for="(property, index) in properties" :key="property.slug" :class="{'property_selected' : checkboxProperty.includes( property.slug )}") 
            .block-img.mt-auto.mx-auto
              v-img(:src="getImgUrl(property.slug)" mx-width="47" max-height="50" contain)
            .block-check.pl-3
              v-checkbox(v-model="checkboxProperty" :value="property.slug" :label="property.label" color="#3A84FF" hide-details off-icon="mdi-checkbox-blank-outline" on-icon="mdi-checkbox-intermediate" :disabled="!checkboxProduct")
            tooltip(v-if="property.description" :description="property.description")
      v-col.text-center(cols="12")
        v-btn.mr-10.btn-app(outlined color="#767676" @click="clear") #[translate Clear all]
        v-btn.btn-app(color="primary" :disabled="!checkboxProduct || checkboxProperty.length === 0" @click="showResults()") #[translate Show my results]

    // BLOCK RESULTS
    v-row.block-results.mx-auto.mt-8.flex-column(v-if="showList")
      v-row.block-result.container.mx-auto.mt-8.w-100.pb-0.px-0
        v-col.pl-0.px-0(cols="7" md="6")
          p.title-block.primary--text #[translate Recommended materials:]
        v-col.d-flex.px-0.align-baseline(cols="5" md="4")
          p.title-result #[translate Temperature ranges]
          .block-tooltip-temperature.mt-1.ml-2
            v-menu(:close-on-content-click="false" nudge-width="213" nudge-right="20" nudge-top="10" offset-x max-width="213" open-on-hover)
              template(v-slot:activator="{ on, attrs }")
                div.img-tooltip(v-bind="attrs" v-on="on")
              .tooltip
                p #[translate Approximate information, depending on the supplier and duration]
        v-col.d-none.d-md-flex.align-baseline.justify-center(cols="2")
          p.text-center.title-result #[translate Material info]

      // - NO RESULTS
      v-row.block-result.container.mx-auto.mt-0.w-100.justify-center(v-if="materials.length === 0")
        p.title #[translate Unfortunately there is no material available with all the properties you are looking for.]

      // - BLOCK ITEMS
      item(v-for="(material, index) in materials" :key="material.slug" :name="material.label" :temperature="material.temperature" :description="material.description" :type="material.material_type" :has_plastic="material.contains_plastic")

      // - BLOCK ALERT
      v-row.container.block-alert.mx-auto.mt-9(v-if="materials.length > 0")
        v-col(cols="3" md="1")
          v-img(:src="img.dot_alert_big")
        v-col(cols="9" md="11")
          .cupsText.d-flex.align-center(v-if="productSearchSelected == 'cups' ")
            .img
              v-img.mr-3(:src="img.ico_reduced" width="22" height="22" )
            p.font-14.mb-0.orange--text.font-weight-bold #[translate Cups are affected by the reduction targets of the EU single-use plastic directive.]
          .other(v-if="productSearchSelected != 'cups' ")
            .block.d-flex.align-center
              .img
                v-img.mr-3(:src="img.ico_forbidden" width="22" height="22" )
              p.font-14.mb-0.text-alert.font-weight-bold #[translate Plates / cutlery / stirrers are affected by the ban on the single-use plastic directive.]
          p.mb-0.text-alert.d-none.d-sm-block #[translate ATTENTION EU-SINGLE-USE-PLASTICS DIRECTIVE]
          p.font-14.mb-0.d-none.d-sm-block #[translate Straws, plates and cutlery, stirrers made of plastic (or partially made of plastic) may no longer be placed on the market from July 3, 2021, cups and food containers made of these materials should be reduced (bowls are a gray area that is insufficiently defined). For more detailed information on the directive check out our INPACS Disposables Handbook! If you don't have it yet, feel free to contact us.]
        v-col(cols="12")
          p.mb-0.text-alert.d-sm-none #[translate ATTENTION EU-SINGLE-USE-PLASTICS DIRECTIVE]
          p.font-14.mb-0.d-sm-none #[translate Straws, plates and cutlery, stirrers made of plastic (or partially made of plastic) may no longer be placed on the market from July 3, 2021, cups and food containers made of these materials should be reduced (bowls are a gray area that is insufficiently defined). For more detailed information on the directive check out our INPACS Disposables Handbook! If you don't have it yet, feel free to contact us.]
      // - BLOCK INFO
      v-row.container.block-note.mx-auto.my-7
        v-col(cols="12")
          p.mb-0 #[translate Important note:]
          p.mb-0 #[translate We always recommend testing the material to make sure it works at any situation. Please also check always the manufacturer information about the product.]
          p.mb-0 #[translate We do not make any claim on completeness and correctness.]
    appFooter      
</template>
<script>
import tooltip from '@/components/tooltip'
import item from '@/components/item-result'
import appFooter from '@/components/footer'
import appHeader from '@/components/header'
import SrvConfigurator from '@/services/configurator.js';
// import SrvCommon from '@/services/common.js';
//JWT_decode
// import jwt_decode from "jwt-decode";

export default {
  components: {tooltip, item, appFooter, appHeader},
  name: 'Home',
  data() {
    return {
      products: [],
      properties: [],
      materials:[],
      checkboxProduct: '',
      productSearchSelected: '',
      checkboxProperty: [],
      showList: false,
      img: {
        logo: require('@/assets/img/logo.png'),
        cups: require('@/assets/img/cup.png'),
        "cutlery-stirring-sticks": require('@/assets/img/cutlery.png'),
        "plates-bowls": require('@/assets/img/bowl.png'),
        power: require('@/assets/img/power.png'),
        tooltip: require('@/assets/img/info.png'),
        tooltip_hover: require('@/assets/img/info-hover.png'),
        dot_alert_big: require('@/assets/img/dot-alert-big.png'),
        ico_forbidden: require('@/assets/img/ico_prohibicion.png'),
        ico_reduced: require('@/assets/img/ico_reduccion.png')
      },
      placeholder: {
        'cup': this.$gettext('Cups'),
        'cutlery': this.$gettext('Cutlery/Stirring Sticks ...'),
        'bowl': this.$gettext('Plates/Bowls'),
      },
    }
  },
  created(){
    this.getProducts()
    this.getProperties()
  },
  computed: {
  },
  methods: {
    getProducts() {
      SrvConfigurator.getProducts()    
          .then(res => {
            if (res.status === 200) {
              this.products = res.data
            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })

    },
    getProperties() {
      SrvConfigurator.getProperty()    
          .then(res => {
            if (res.status === 200) {
              this.properties = res.data
            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
    },
    getMaterials(products, properties) {
      SrvConfigurator.getMaterial(products, properties)    
          .then(res => {
            if (res.status === 200) {
              this.materials = res.data
              this.productSearchSelected = products
              this.showList = true
            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
    },
    getImgUrl(name) {
      return require('../assets/img/'+ name + ".png")
    },
    clear() {
      this.checkboxProduct= '',
      this.checkboxProperty= [],
      this.showList = false
    },
    showResults() {
      this.getMaterials(this.checkboxProduct, this.checkboxProperty)
    },
  }
}
</script>
<style lang="scss">
.configurator{
  overflow: hidden;
  background-color: #F5F6F8;
  
  .container{
    max-width: 1300px;
    padding-bottom: 15px;
  }
  .block-products{
    .title-block{
      font-family: 'Roboto Slab';
      font-size: 20px;
    }
    .products{      
      background-color: #fff;
      border-radius: 10px;
      .product{
        min-width: 400px;
        max-width: 500px;
        .check-products{
          margin-bottom: 5px;
          margin-top: 25px;
          label{
            color:#43425D;
          }
        }
        .text-desciption{
          font-size: 14px;
          color:#43425D;
        }
      }
    }
  }
  .block-properties{
    .title-block{
      color: #43425D;
      font-size: 18px;
    }
    .propertie{
      max-width: 400px;
      background-color: #fff;
      flex: 0 0 100%;
      margin: 5px auto;
      height: 67px;
      position: relative;
      flex-wrap: nowrap;
      padding: 5px 12px 12px 12px;
      &.property_selected{
        outline: 1px solid #0D6AFF;
      }
      .block-img{
        // width: 12%;
      }
      .block-check{
        width: 84%;
        display: flex;
        align-items: center;
        .v-label{        
          color: #43425D;
          font-size: 12px;
          font-weight: 500;
          &::first-letter{
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .block-sustainability{
    .title-block{
      color: #43425D;
      font-size: 18px;
    }
    .sustainability{
        background-color: #E6EBC0;
        flex: 0 0 100%;
        max-width: 400px;
        margin: 5px auto;
        height: 67px;
        position: relative;
        flex-wrap: nowrap;
        padding: 5px 12px 12px 12px;
        &.property_selected{
          outline: 1px solid #0D6AFF;
        }

        .block-check{
        width: 84%;
        display: flex;
        align-items: center;
        .v-label{        
          font-size: 12px;
          color: #43425D;
          font-weight: 500;
        }
      }
    }
  }
  .block-results{
    background-color: #ddc1663f;
    .block-result{      
      width: 90%;
      .title-block{
        font-size: 18px;
      }
      .title-result{
        font-size: 13px;
        color:#43425D;
      }
      .block-tooltip-temperature{
        .img-tooltip{
          display: block;
          width: 16px;
          height: 16px;
          background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAWxJREFUOE99k71dAkEQxf9jpCZgB3YgIUciVCBUICUsFYgVsHZwViBWICYsmdiBHQiJGrH+ZveO+wIm2tu9eW/ezBuhHj3TAe7w9BH0DJ41wgJ4ZWnX5RSp5CfGIMwaoMXFBi9T3OwpvyoAemYemCPjM56UPyLbOR2EMcJ99j7H2ZGeI0DO7NniGbKyWm4zukZlzRFaeCY4a4Wo+SP8vWNQSU6MDfU4O9mjKcgZb8AGGCjAFHgIZTs7rvXkK3w7e127TzM5j0JiFgi3DXbN6Jh2SFxbZSsir8LzrgCboOmHq8aP2ptYgUopQoEv+VYZpwF6xoespa2OOwfwbE9LOAZQkXCqiccAElNqoo7Ro41sHRhjbJ6zsZkaBfsWoV81ks52x2jvha6J41vZOM6Y/AK0CyPlyGUrQ4qXlF//GZ4v5Abx6pHcJ7pUQ31qLhNMg5xDoVbX99JYqwCaFK09DOtMts5k67wj3cvJCP4BnTemHVCid5gAAAAASUVORK5CYII=");
          &:hover{
          background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAURJREFUOE+Vk7FOAkEQhr8pTYwSK0sewRYsoLMUYuJreJQ2IjaUrD6BtYkJlnZcodjJI1BamYOY2Dlmd1nvgOXUqS67838zc/uPsBr15AA4Rmki2G9QJggj4IFnMylKZElfSxKEwRo0HCgzkC7jwXU4ygH1ZOgq+4rxCNnKkLFp2yR/FCoH4XJfOax4r3QYGyP4mV9/KhfE5qyNqtK5sc0twkJsjh1HaFrAJdB1ba9Unt5fOFX15Gp5pDy3J9SSEUIjBqhsbzlh9vEZByipBWQIuzFActpwQnOXbuogKwXok39ROexs6mBWOsIfAGnpT/wVAD3/jKopIjur/yF77LvWK0fnkWfUOSKNUiNV9/eccPr27gFRIwX2f6zsl6qVWzlArKXRnhsnFqpzv0zGhOt113trt9w6s1hnFuv8xS0vZlpkfwOBTYEdh9mjvgAAAABJRU5ErkJggg==");

          }
        }
      }
    }
    .block-alert{
      .text-alert{
        color:#EC4444;
        font-weight: 500;
      }
    }
    .block-note{
      background-color: #003A70;
      width: 95%;
      font-size: 14px;
      border-radius: 15px;
      color: #fff;
    }
  }
}
.v-menu__content{
  overflow-x: unset;
  overflow-y: unset;
  contain: unset;
  background-color: #003A70;
  color: #fff;
  font-size: 12px;
}
.tooltip{
  &::before{
    content:"\A";
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #003A70 transparent transparent;
    position: absolute;
    left: -15px;
    top: 8px;
  }
  p{
    padding: 12px;
  }
}
.btn-app{
  margin-bottom: 10px;
}

@media (min-width: 640px) {
.configurator{
  .block-properties{
    .propertie{
      max-width: 310px;
    }
  }
  .block-sustainability{
    .sustainability{
        max-width: 310px;
    }
  }
  .block-results{
    .block-result{      
      .title-block{
        font-size: 22px;
      }
      .title-result{
        font-size: 16px;
      }
    }
  }
}
.btn-app{
  margin-bottom: 30px;
}
}
 @media only screen and (max-width: 402px) {
    .block-products{
      margin-top: 45px!important;
    }
  }
</style>