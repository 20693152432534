// import Vue from 'vue'
// import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import forgot from '../views/forgot.vue'
import reset from '../views/reset.vue'
import Configurator from '../views/Configurator.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {Auth: false}
  },
  {
    path: '/forgot-password',
    name: 'forgot',
    component: forgot,
    meta: {Auth: false}
  },
  {
    path: '/reset-password',
    name: 'reset',
    component: reset,
    meta: {Auth: false}
  },
  {
    path: '/configurator',
    name: 'configurator',
    component: Configurator,
    meta: {Auth: true},
  }
]

export default routes
