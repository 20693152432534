/* App */
export const PENDING = 'PENDING' // Para cuando consultamos la api y todavía no hemos recibido respuesta.
export const NOT_PENDING = 'NOT_PENDING'

/* Auth - USER */
export const LOGIN = 'LOGIN' // Para logarse
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS' // una vez estemos logados
export const LOGOUT = 'LOGOUT' // para hacer logout
export const REFRESH_TOKEN = 'REFRESH_TOKEN' // para hacer logout
export const LANG = 'LANG' // para hacer logout
