// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
//JWT_decode
import jwt_decode from "jwt-decode";
import Vue from 'vue'

const domain = window.location.hostname
let api = ''

if (domain === 'disposablesconfigurator.inpacs.com') {
  api = 'https://disposablesconfigurator.inpacs.com/backend/api/v1'
} else if(domain === 'disposablesconfigurator.theysay.me') {
  api = 'https://disposablesconfigurator.theysay.me/backend/api/v1/'
} else{
  api = 'https://disposablesconfigurator.inpacs.com/backend/api/v1'
}

// Axios
import axios from 'axios'
axios.defaults.baseURL = api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept-Language'] = Vue.config.language
export default {
  verifyToken () {
    let token = '';
    if(window.localStorage.getItem('_token')){
      token = window.localStorage.getItem('_token');
    }else if(window.sessionStorage.getItem('_token')){
      token = window.sessionStorage.getItem('_token');
    }
    if (token) {
      const decode = jwt_decode(token)
      const exp = decode.exp

      const timeOne = 86400 // 1 dia => 86400, 4h => 14400, 5 min = 300 = 5*60
      // const timeTwo = 3600 // 7 días => 604800, 1h = 3600 = 1*60*60

      const now = parseInt((Date.now() / 1000).toFixed(0))
      // console.log(`el token expira: ${exp}, hora actual: ${now}`)

      if ((exp - timeOne) <= now ) {
        // this.dispatch('refreshToken')
        this.refreshToken()
      } 
    }
    return false
  },
  refreshToken () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API
      let token = '';
      let storage = ''
      if(window.localStorage.getItem('_refresh')){
        storage = 'local'
        token = window.localStorage.getItem('_refresh')
      }else if(window.sessionStorage.getItem('_refresh')){
        storage = 'sesion'
        token = window.sessionStorage.getItem('_refresh')
      }
      axios.post('/token/refresh/', {'refresh': token})
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('**---------->refresh', res)
          if(storage === 'local') {
            window.localStorage.setItem('_token', res.data.access)
          } else {
            window.sessionStorage.setItem('_token', res.data.access)
          }
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('----error checkToken: ' + error)
          this.logout()
          reject(error)
        })
    })
  },
  logout () {
    const isLoggedIn = Stores.getters.isLoggedIn
    if (isLoggedIn) {
      Stores.dispatch('logout')
    }
    return false
  }
}