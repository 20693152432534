<template lang="pug">
    .line-result.mx-auto.w-100.row.ma-0
      v-col.boder-bottom-result.dots.py-2.pr-0.pl-1.px-sm-2(cols="2" sm="1")
        .dot(:class="type")
        .dot_alert.ml-3(v-if="type === 'plastic' || has_plastic === true" )
          v-img(:src="img.dot_alert")
      v-col.boder-bottom-result.py-2.pl-md-0(cols="6" sm="5")
        p.text-block.mb-0.break-overflow(:class="type") {{name}}
      v-col.boder-bottom-result.px-0.py-2(cols="3" sm="4")
        p.text-block.mb-0.pa-0.pl-md-4.text-block-min {{temperature}}
      v-col.boder-bottom-result.text-center.py-2(cols="1" sm="2")
        v-icon(v-if="!showDescription" color="#2680EB" @click="showDescription = !showDescription") mdi-chevron-right
        v-icon(v-if="showDescription" color="#2680EB" @click="showDescription = !showDescription") mdi-chevron-down
      v-col.description(cols="12" v-if="showDescription")
        .descipt
          p {{description}}      
</template>

<script>

export default {
    props: ['name','temperature','description', 'type', 'has_plastic'],
    data (){
      return{
        showDescription : false,
        img: {
          dot_alert: require('@/assets/img/dot-alert.png'),
        }
      }
    },   
    methods:{

    } 
}
</script>

<style lang="scss" scoped>
  .line-result{
    background-color: #fff;
    max-width: 1300px;
    width: 95%;
    .dots{
      display: flex;
      align-items: center;
      .dot{
        height: 22px;
        width: 22px;
        background-color: #bbb;
        border-radius: 50%;
        &.wood{
          background-color: #DDC166;
        }
        &.plastic{
          background-color: #BFD9F1;
        }
        &.metal{
          background-color: #A89CBF;
        }
      }
    }
    .text-block{
      font-size: 16px;
      &.wood{
       color: #DDC166;
      }
      &.plastic{
       color: #BFD9F1;
      }
      &.metal{
       color: #A89CBF;
      }
      &.break-overflow{
        max-height: 26px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .text-block-min{
      font-size: 13px;
      color: #52575A
    }
    .description{
      background-color: #ede8d5;
      .descipt{
        max-width: 1100px;
        margin: auto;
      }
    }
    .boder-bottom-result{
      border-bottom: 1px solid #ede8d5;
    }
  }
  @media (min-width: 640px) {
    .line-result{
      .text-block{
        font-size: 18px;
      }
      .text-block-min{
        font-size: 18px;
      }
    }
  }
</style>
