import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueRouter from 'vue-router'
import routes from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'

// Idioma
import GetTextPlugin from 'vue-gettext'
import translations from '@/translations/translation.json'

import SrvCommon from '@/services/common'

// Cargamos iconos y fuentes para vuetify
let iconosVuetify = document.createElement("link")
iconosVuetify.href = 'https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css'
iconosVuetify.rel = 'stylesheet'
document.querySelector("head").appendChild(iconosVuetify)

Vue.config.productionTip = false

let userLocale = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage)
userLocale = (userLocale.startsWith('es-')) ? 'es' : userLocale
// Configuración para idiomas
Vue.use(GetTextPlugin, {
  availableLanguages: {
    en: 'English',
    el: 'Greek',
    it: 'Italian',
    de: 'German',
    // nl: 'Nederlands'
    // sw: 'Swedish',
  },
  defaultLanguage: userLocale,
  languageVmMixin: {
    computed: {
      currentKebabCase: function () {
        return this.current.toLowerCase().replace('_', '-')
      }
    }
  },
  translations: translations,
  silent: true
})

Vue.use(VueRouter)

// Configuración del router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 2500)
      })
    } else {
      return { x: 0, y: 10 }
    }
  }
})
router.beforeEach((to, from, next) => {
  document.body.className = `body-${to.name}`
  // Comprobamos si la ruta necesita autentificación y si el usuario esta logado
  if (to.meta.Auth && !store.state.user.isLoggedIn) {
    // Verificamos si viene el token.
    let poToken = null;
    if(window.localStorage.getItem('_token')){
      poToken = window.localStorage.getItem('_token');
    }else if(window.sessionStorage.getItem('_token')){
      poToken = window.sessionStorage.getItem('_token');
    }
    if (poToken) {
        store.dispatch('autoLogin')
    } else {
      // Si no viene el token, mandamos al logueo.
      next({path: '/'})
    }
  }
  SrvCommon.verifyToken()
  next()
})

new Vue({
  router,
  store,
  axios,
  vuetify,
  render: h => h(App)
}).$mount('#app')
