import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#4C9B9A',
        secondary: '#94D1D6',
        terciary: '#CCDB75',
        accent: '#8c9eff',
        error: '#b71c1c',
        disposable_gray: '#4D4F5C'
      },
    },
  },
});

