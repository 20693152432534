<template lang="pug">
   .block-tooltip.ml-auto
      v-menu(:close-on-content-click="false" nudge-width="213" nudge-right="20" nudge-top="10" offset-x max-width="213" open-on-hover )
        template(v-slot:activator="{ on, attrs }")
          div.img-tooltip(v-bind="attrs" v-on="on")
        .tooltip
          p {{description}}
</template>

<script>

export default {
    props: ['description'],
    data (){
        return{
        }
    },   
    methods:{

    } 
}
</script>

<style lang="scss" scoped>
.block-tooltip{
  position: absolute;
  right: 7px;
  .img-tooltip{
    display: block;
    width: 16px;
    height: 16px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAWxJREFUOE99k71dAkEQxf9jpCZgB3YgIUciVCBUICUsFYgVsHZwViBWICYsmdiBHQiJGrH+ZveO+wIm2tu9eW/ezBuhHj3TAe7w9BH0DJ41wgJ4ZWnX5RSp5CfGIMwaoMXFBi9T3OwpvyoAemYemCPjM56UPyLbOR2EMcJ99j7H2ZGeI0DO7NniGbKyWm4zukZlzRFaeCY4a4Wo+SP8vWNQSU6MDfU4O9mjKcgZb8AGGCjAFHgIZTs7rvXkK3w7e127TzM5j0JiFgi3DXbN6Jh2SFxbZSsir8LzrgCboOmHq8aP2ptYgUopQoEv+VYZpwF6xoespa2OOwfwbE9LOAZQkXCqiccAElNqoo7Ro41sHRhjbJ6zsZkaBfsWoV81ks52x2jvha6J41vZOM6Y/AK0CyPlyGUrQ4qXlF//GZ4v5Abx6pHcJ7pUQ31qLhNMg5xDoVbX99JYqwCaFK09DOtMts5k67wj3cvJCP4BnTemHVCid5gAAAAASUVORK5CYII=");
    &:hover{
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAURJREFUOE+Vk7FOAkEQhr8pTYwSK0sewRYsoLMUYuJreJQ2IjaUrD6BtYkJlnZcodjJI1BamYOY2Dlmd1nvgOXUqS67838zc/uPsBr15AA4Rmki2G9QJggj4IFnMylKZElfSxKEwRo0HCgzkC7jwXU4ygH1ZOgq+4rxCNnKkLFp2yR/FCoH4XJfOax4r3QYGyP4mV9/KhfE5qyNqtK5sc0twkJsjh1HaFrAJdB1ba9Unt5fOFX15Gp5pDy3J9SSEUIjBqhsbzlh9vEZByipBWQIuzFActpwQnOXbuogKwXok39ROexs6mBWOsIfAGnpT/wVAD3/jKopIjur/yF77LvWK0fnkWfUOSKNUiNV9/eccPr27gFRIwX2f6zsl6qVWzlArKXRnhsnFqpzv0zGhOt113trt9w6s1hnFuv8xS0vZlpkfwOBTYEdh9mjvgAAAABJRU5ErkJggg==");

    }
  }
}
.tooltip{
  &::before{
    content:"\A";
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #003A70 transparent transparent;
    position: absolute;
    left: -15px;
    top: 8px;
  }
  p{
    padding: 12px;
  }
}

</style>