// Stores
// import Stores from '@/store/index'
// // importamos los tipos de mutación de vuex
// import * as types from '@/store/mutation-types'
// import GetTextPlugin from 'vue-gettext'
import Vue from 'vue'

const domain = window.location.hostname
let api = ''

if (domain === 'disposablesconfigurator.inpacs.com') {
  api = 'https://disposablesconfigurator.inpacs.com/backend/api/v1'
} else if(domain === 'disposablesconfigurator.theysay.me') {
  api = 'https://disposablesconfigurator.theysay.me/backend/api/v1/'
} else{
  api = 'https://disposablesconfigurator.inpacs.com/backend/api/v1'
}

// Axios
import axios from 'axios'
axios.defaults.baseURL = api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept-Language'] = Vue.config.language

export default {
  getProducts () {
    axios.defaults.headers.common['Accept-Language'] = Vue.config.language
    let token = '';
    if(window.localStorage.getItem('_token')){
      token = window.localStorage.getItem('_token');
    }else if(window.sessionStorage.getItem('_token')){
      token = window.sessionStorage.getItem('_token');
    }
    // llamada a la API
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    return new Promise((resolve, reject) => {
      // mostramos Loading
      axios.get(`/product/`)
        .then(res => {
          // Ocultamos Loading

          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          // const status = error
          reject(error)
        })
    })
  },
  getProperty () {
    axios.defaults.headers.common['Accept-Language'] = Vue.config.language
    let token = '';
    if(window.localStorage.getItem('_token')){
      token = window.localStorage.getItem('_token');
    }else if(window.sessionStorage.getItem('_token')){
      token = window.sessionStorage.getItem('_token');
    }
    // llamada a la API
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

    return new Promise((resolve, reject) => {
      // mostramos Loading
      axios.get(`/property/`)
        .then(res => {
          // Ocultamos Loading

          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          // const status = error
          reject(error)
        })
    })
  },
  getMaterial (products, properties) {
 
    axios.defaults.headers.common['Accept-Language'] = Vue.config.language
    let token = '';
    if(window.localStorage.getItem('_token')){
      token = window.localStorage.getItem('_token');
    }else if(window.sessionStorage.getItem('_token')){
      token = window.sessionStorage.getItem('_token');
    }
    // llamada a la API
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

    return new Promise((resolve, reject) => {
      // mostramos Loading
      axios.get(`/material/?product=${products}&property=${properties}`)
        .then(res => {
          // Ocultamos Loading

          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          // const status = error
          reject(error)
        })
    })
  },

}