<template lang="pug">
  .footer.text-center.mt-3.py-4
    p.subFooter.mb-0 #[translate In case of any questions regarding the tool please contact] #[a.text-decoration-none(href="mailto:vanessa.hahn@inpacs.com") Vanessa Hahn ]
    p.copy Copyright © {{currentYear}} INPACS GmbH  

</template>

<script>

export default {
    data (){
      return{
        currentYear: new Date().getFullYear(), // 2020
      }
    },   
    methods:{

    } 
}
</script>

<style lang="scss" scoped>
  .footer{
    background-color: #ddc1663f;
    .subFooter{
      color:#003A70;
      padding: 15px;
    }
    .copy{
      font-size: 14px;
    }
  }
</style>
