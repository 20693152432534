import * as types from '@/store/mutation-types'

const user = {
  state: {
    isLoggedIn: false, // Si no esta el token en False y si lo esta es true
    pending: false,
    refreshToken: '',
    lang: ''
  },
  actions: {
    // login ({commit}, user) {
    //   commit(types.PENDING) // Para mostrar el preload

    //   return new Promise((resolve, reject) => {
    //     axios.post('/api-token-auth/', user)
    //       .then(res => {
    //         resolve(res)
    //       })
    //       .catch(error => {
    //         commit(types.NOT_PENDING)
    //         reject(error.response)
    //       })
    //   })
    // },
    autoLogin ({commit}) {
      commit('LOGIN_SUCCESS')

    },
    logout ({commit}) {
      window.localStorage.removeItem('_token')
      window.sessionStorage.removeItem('_token')
      // Reseteamos el país
      commit(types.UPDATE_USER_COUNTRY, null)
      commit(types.LOGOUT)
    }, 
    
    setUser ({commit}, data) {
      commit(types.SET_USER, data)
    },
    setLang ({commit}, data) {
      commit(types.LANG, data)
    },
  },
  mutations: {
    [types.LOGIN_SUCCESS] (state) {
      state.isLoggedIn = true
    },
    [types.LOGOUT] (state) {
      state.isLoggedIn = false
    },
    [types.REFRESH_TOKEN] (state, data) {
      state.refreshToken = data
    },
    [types.PENDING] (state) {
      state.pending = true
    },
    [types.NOT_PENDING] (state) {
      state.pending = false
    },
    [types.LANG] (state, data) {
      state.lang = data
    },
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn
    },
    refreshToken: state => {
      return state.refreshToken
    },
    get_lang: state => {
      return state.lang
    }
  }
}

export default user