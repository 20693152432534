<template lang="pug">
  v-container.login.pa-0.heigth-100(fluid)
    v-row.align-center
      v-col.d-none.d-md-flex.heigth-100(cols="5")
        v-img.heigth-100(:src="img.background_login")
      v-col.pt-5(cols="12" md="7")
        v-row.flex-column.align-center.px-10.mt-10.mt-sm-auto.text-center
          div.ml-auto
            v-menu(transition="slide-y-transition" bottom offset-y content-class="noShadow")
              template(v-slot:activator="{ on, attrs }")
                v-btn.btn-lang.text-capitalize( v-bind="attrs" v-on="on" text tile color="#4d4f5c87") {{$language.available[$language.current]}}
                  v-img.ml-3(:src="getImgUrl($language.current)" width="15" height="15")
                  v-icon(right color="primary") mdi-chevron-down
              v-list.text-right.list-lang
                v-list-item.p-0(v-if="$language.current !== i" v-for="(item, i) in $language.available" :key="i" @click="setLenguage(i)")
                  v-list-item-title.disposable_gray--text.opacity-50 {{ item }}
                  .block-img.pl-2.pr-4
                    v-img(:src="getImgUrl(i)" width="15" height="15" )
          v-img( :src="img.logo" max-width="213")
          h1.mt-5 #[translate Disposables Configurator]
          p.primary--text.font-weight-light.subtitle #[translate Find the right material with the properties you are looking for!]
          v-img.my-3(:src="img.ue")
          span.opacity-50.mt-5.disposable_gray--text #[translate Welcome back! Please login to your account.]
          .blockError
            p.pt-1(v-if="errorMsg") {{errorMsg}}
          form#form_login(ref="formUser")
            div.margin-bottom-32
              v-text-field.field-login(v-model="username" :placeholder="placeholder.username" ref="username" @focus="resetError()")
            div.margin-bottom-32
              v-text-field.field-login(v-model="password" :placeholder="placeholder.pwd" ref="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" @focus="resetError()")
            v-row
              v-col.text-left(md="5")
                v-checkbox.remember.mt-0(v-model="RememberCheck" :label="checkPlaceholder" off-icon="mdi-checkbox-blank-outline" on-icon="mdi-checkbox-intermediate")
              v-col.text-right(md="7")
                p.forgot-link(@click="goForgot()") #[translate Forgot Password]
            div.text-center
              v-btn.btn-app.mb-0(color="primary" type="button" width="167" height="45" @click="send" @keyup.enter="send" :disabled="!username || !password") #[translate Login]
          // MODAL INVITATION
          v-dialog(v-model="dialog" width="570")
            template(v-slot:activator="{ on, attrs }")
              v-row.mt-15(align="center" justify="space-around")
                v-img(:src="img.invitation" max-width="25" v-bind="attrs" v-on="on")
                p.mb-0.invitation(v-bind="attrs" v-on="on") #[translate Request an invitation]
            v-card
              v-card-title
                v-icon.ml-auto.mt-4.mr-2(@click="dialog = false") mdi-close-circle-outline
              v-card-text 
                v-container.dialog-container
                  v-row(v-if="!inviteOk")
                    v-col(cols="12")
                      h2.title-modal.text-center #[translate Request an invitation]
                      .blockError.text-center
                        p.pt-1(v-if="errorInvite") {{errorInvite}}
                    v-col.pb-0(cols="12")
                      v-text-field.field-modal(v-model="usernameInvite" :label="placeholder.username_modal" required @focus="resetError()")
                    v-col.py-0(cols="12")
                      v-text-field.field-modal(v-model="companyInvite" :label="placeholder.company" required @focus="resetError()")
                    v-col.pt-0(cols="12")
                      v-text-field.field-modal(v-model="emailInvite" :label="placeholder.email" required @focus="resetError()")
                    v-col(cols="12")
                      p.accepting #[translate With clicking "Send“ you are accepting our] &nbsp
                        a.text-decoration-none(href="https://www.inpacs.com/policies" target="_blanc") #[translate Privacy policy].
                    v-col(cols="12").text-center
                      v-btn.btn-app.mb-0(color="primary" type="button" width="167" height="45" @click="invite" @keyup.enter="invite" :disabled="!usernameInvite || !companyInvite || !emailInvite") #[translate Send]
                  v-row(v-if="inviteOk")
                    v-col.text-center(cols="12")
                      h2.title-modal.text-center.mb-10 #[translate Request an invitation]
                      v-img.mx-auto.mb-5(:src="img.smile" width="20" height="20" )
                      p.mb-0 #[translate Many thanks for your interest.]
                      p.mb-10 #[translate You will receive an invitation shortly.]
                      v-btn.btn-app.mb-0.mt-15(color="primary" type="button" width="167" height="45" @click="dialog = false") #[translate Close]
          p.mt-10.copy #[translate Copyright © 2021 INPACS GmbH]
</template>

<script>
import SrvLogin from '@/services/login.js';

export default {
  name: 'Home',
  data() {
    return {
      dialog: false,
      img: {
        background_login: require('@/assets/img/login/fondo_login.png'),
        ue: require('@/assets/img/login/EU_directive.png'),
        logo: require('@/assets/img/logo.png'),
        invitation: require('@/assets/img/login/invitation.png'),
        smile: require('@/assets/img/login/smile.png')
      },
      username: null,
      password: null,
      usernameInvite: null,
      companyInvite: null,
      emailInvite: null,
      errorMsg: false,
      errorInvite: false,
      showPassword: false,
      placeholder: {
        'username': this.$gettext('Username'),
        'pwd': this.$gettext('Password'),
        'check': this.$gettext('Remember me'),
        'username_modal': this.$gettext('Name'),
        'company': this.$gettext('Company'),
        'email': this.$gettext('Email'),
      },
      //page_password_reset: this.$config.page_password_reset,
      RememberCheck: false,
      inviteOk: false,
      avaiableLang: ['en','el','it','sw','de']
    }
  },
  created() {
    if(window.localStorage.getItem("_token") || window.sessionStorage.getItem("_token")) {      
      // INTENTAR HACER LOGIN
      this.autoLogin()
    }
    if(!this.avaiableLang.includes(this.$language.current)){
      this.$language.current = 'en'
    }
    this.$store.commit('LANG', this.$language.current)
    
  },
  mounted() {
    this.$refs.username.focus()
    this.$refs.password.focus()
    this.$refs.formUser.focus()
  },
  components: {
  },
  computed: {
    checkPlaceholder  ( )  { 
      return this.$gettext('Remember me' ) 
    } 
  },
  methods: {
    resetError () {
      this.errorMsg = false
      this.errorInvite = false
    },
    send () {
      let username = this.username
      let pwd = this.password
      // this.resetError()
      // let RegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
      // if (username === null || username === '' || !username.match(RegEx)) {
      //   this.errorMsg = this.$gettext('Username is an invalid email')
      // }

      // if (!pwd) {
      //   this.errorMsg = true
      //   this.errorPwd = true
      // }


      SrvLogin.getToken({username: username, password: pwd})
        .then(res => {
          if(this.RememberCheck){
            window.localStorage.setItem('_token', res.data.access)                        
            window.localStorage.setItem('_refresh', res.data.refresh)                        
          }else{
            window.sessionStorage.setItem('_token', res.data.access)
            window.sessionStorage.setItem('_refresh', res.data.refresh)
          }
          this.$store.commit('REFRESH_TOKEN', res.data.refresh)
          
          this.autoLogin()
        }, (error) => {
            this.errorMsg = error.data.detail ? error.data.detail : true
        })

    },
    autoLogin () {
      this.$store.commit('LOGIN_SUCCESS')
      this.$router.push({name: 'configurator'})
    },
    invite () {
      if(this.usernameInvite && this.companyInvite && this.emailInvite) {
        SrvLogin.getInvite({name : this.usernameInvite, company: this.companyInvite, email : this.emailInvite})
          .then(res => {     
            console.log(res)
            this.inviteOk = true
          }, (error) => {
            this.errorInvite = error.data.email ? error.data.email[0] : true
          })
      }
    },
    goForgot () {
      this.$router.push({name: 'forgot'})
    },
    setLenguage(lang){
      this.$language.current = lang
      this.$store.commit('LANG', this.$language.current)

    },
    getImgUrl(name) {
      if (name && this.avaiableLang.includes(name)) {
        return require('@/assets/img/logos/'+ name + ".svg")
      } 
      return ''
    },
  }
}
</script>
<style lang="scss">
  .heigth-100{
    height: 100vh;
  }
  .login{
    h1{
      font-family: 'Roboto Slab';
      font-size: 28px;
      color: #003A70;
      font-weight: bold;

    }
    .subtitle{
      font-size: 20px;
    }
    #form_login{
      width:100%;
      max-width: 360px;
      .field-login{
        .v-label{
          color: var(--v-disposable_gray-base);
          font-size: 15px;
        }
        input{
          font-size: 15px;
        }
      }
      .remember{
        .v-label{
          font-size: 13px;
          color: #43425D;
        }
      }
      .forgot-link{
        font-size: 13px;
        color:#43425D;
        cursor: pointer;
      }
    }
    .invitation{
      color:#0D6AFF;
      cursor: pointer;
      margin-top: 12px;
    }
    .copy{
      font-size: 10px;
    }
  }
  .container.dialog-container{
    max-width: 400px;
    h2{
      font-family: 'Roboto Slab';
      font-size: 22px;
      color: #003A70;
      font-weight: bold;
    }
    .field-modal{
      .v-label{
        color: var(--v-disposable_gray-base);
        font-size: 15px;
      }
      input{
        font-size: 15px;
      }
    }
    .accepting{
      font-size: 12px;
      color: #43425D;
    }
  }
.blockError{
  height: 10px;
  p{
    color:#EC4444
  }
}
.body-home{
  .btn-lang{
    border-bottom: 1px solid #d6c8c8;
    font-size: 12px!important;
  }
  .v-list-item{
    min-height: 28px;
    .v-list-item__title{
      font-size: 12px;
    }
  }
  .noShadow{
    box-shadow: unset;
  }
}
</style>