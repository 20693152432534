<template lang="pug">
    v-app(dark)
      div#disposables-login(v-if="$route.name === 'home'")
        router-view
      div#disposables(v-else)
        router-view

</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
@import "./assets/sass/styles.scss";
</style>