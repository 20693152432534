// Stores
// import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
// import * as types from '@/store/mutation-types'
import Vue from 'vue'

const domain = window.location.hostname
let api = ''

if (domain === 'disposablesconfigurator.inpacs.com') {
  api = 'https://disposablesconfigurator.inpacs.com/backend/api/v1'
} else if(domain === 'disposablesconfigurator.theysay.me') {
  api = 'https://disposablesconfigurator.theysay.me/backend/api/v1/'
} else{
  api = 'https://disposablesconfigurator.inpacs.com/backend/api/v1'
}

// Axios
import axios from 'axios'
axios.defaults.baseURL = api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getToken (user) {
    axios.defaults.headers.common['Accept-Language'] = Vue.config.language
    return new Promise((resolve, reject) => {
      axios.post('/token/', user)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getInvite (data) {
    axios.defaults.headers.common['Accept-Language'] = Vue.config.language
    return new Promise((resolve, reject) => {
      axios.post('/invites/', data)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getForgot (email) {
    axios.defaults.headers.common['Accept-Language'] = Vue.config.language
    return new Promise((resolve, reject) => {
      axios.post('/password_reset/', email)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  changePassword (data) {
    axios.defaults.headers.common['Accept-Language'] = Vue.config.language
    return new Promise((resolve, reject) => {
      axios.post('/password_reset/confirm/', data)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  sendConfirm (token) {
    axios.defaults.headers.common['Accept-Language'] = Vue.config.language
    return new Promise((resolve, reject) => {
      axios.get(`/invites/confirm/${token}/`)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
}