<template lang="pug">
  v-row.header(:class="{'header-el': $language.current === 'el'}")
    v-col.row.align-baseline.justify-center.justify-lg-start(cols="12" lg="9")
      v-img.mx-6(:src="img.logo" max-width="123" )
      h1.mx-2 #[translate Disposables Configurator]
      p.ml-2.primary--text.font-weight-light.subtitle #[translate Find the right material with the properties you are looking for!]
    v-col.row.align-center(cols="12" lg="3")
      v-img.ml-auto.d-none.d-lg-block(:src="img.ue" max-width="251" max-height="29" )
      v-img.ml-auto.d-lg-none.mt-n4(:src="img.ue_mobile" max-width="310" max-height="20" )
      v-img.ml-5.mr-2.mr-lg-0.mt-lg-0.mt-n4.cursor-pointer.power(:src="img.power" max-width="14" max-height="16" @click="logout")
</template>

<script>

export default {
    data (){
      return{
        img: {
          ue: require('@/assets/img/login/ue.png'),
          ue_mobile: require('@/assets/img/ue_mobile.png'),
          logo: require('@/assets/img/logo.png'),
          power: require('@/assets/img/power.png')
        },
      }
    },   
    methods:{
      logout() {
        if(window.localStorage.getItem('_token')){
          window.localStorage.removeItem('_token')
          window.localStorage.removeItem('_refresh')
        }else if(window.sessionStorage.getItem('_token')){
          window.sessionStorage.removeItem('_token')
          window.sessionStorage.removeItem('_refresh')
        }
        this.$router.push({name: 'home'})
      }
    } 
}
</script>

<style lang="scss" scoped>
  .header{
    height: 91px;
    background-color: #fff;
    border-bottom: 1px solid #4C9B9A;
    h1{
      font-family: 'Roboto Slab';
      font-size: 18px;
      color: #003A70;
      font-weight: bold;
      margin-top: 35px;
    }
    .subtitle{
      font-size: 14px;
    }
  }
  @media (min-width: 882px) {
    .header{
      height: 74px;
      h1{
        font-size: 22px;
      }
      .subtitle{
        font-size: 16px;
      }
      &.header-el{
        h1{
        font-size: 18px;
      }
      .subtitle{
        font-size: 11px;
      }
      }
    }
  }
  @media only screen and (max-width: 402px) {
    .header{
      margin: 0;
      .row{
        margin: 0;
      }
      h1{
        margin-top: 5px;
      }
      .subtitle{
        text-align: center;
        margin-top: 5px;
      }
      .power{
        position: absolute;
        right: 10px;
        top: 32px;
      }
    }
  }
</style>
